import React from 'react';
import {
  Typography,
  TextField,
  MenuItem,
  Box,
  Button,
  useTheme,
} from '@mui/material';

const services = [
  'Ψυχοθεραπεία Παιδιών',
  'Ψυχοθεραπεία Εφήβων',
  'Συμβουλευτική Γονέων',
  'Εποπτεία Νέων Επαγγελματιών',
];

const ServiceStep = ({ formik, handleNext }) => {
  const theme = useTheme(); // Access the theme

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
        Κλείσε ραντεβού τώρα
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ mb: 3, color: 'gray' }}>
        Προγραμματίστε τη συνεδρία σας και κάντε το πρώτο βήμα προς την ψυχική σας
        ευεξία.
      </Typography>

      <TextField
        fullWidth
        id="service"
        name="service"
        label="Υπηρεσία"
        value={formik.values.service}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        select
        error={formik.touched.service && Boolean(formik.errors.service)}
        helperText={formik.touched.service && formik.errors.service}
        sx={{
          mb: 3,
          '& .MuiOutlinedInput-root': {
            borderRadius: '30px',
          },
        }}
      >
        {services.map((service, index) => (
          <MenuItem key={index} value={service}>
            {service}
          </MenuItem>
        ))}
      </TextField>

      <Button
        variant="contained"
        onClick={handleNext}
        disabled={!formik.values.service}
        sx={{
          backgroundColor: theme.palette.primary.main, // Use theme color
          borderRadius: '30px',
          textTransform: 'none',
          width: '100%',
        }}
      >
        Επόμενο βήμα
      </Button>
    </Box>
  );
};

export default ServiceStep;
