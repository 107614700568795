// emailService.js
import emailjs from 'emailjs-com';

// Replace these with your EmailJS details
const SERVICE_ID = '1service_craulsf';   // Service ID from EmailJS
const TEMPLATE_ID = 'template_073psd9'; // Template ID from EmailJS
const USER_ID = 'haCTl4q0mSHla96vm';         // User ID from EmailJS

/**
 * Sends an email using EmailJS
 * @param {Object} templateParams - The parameters for the email template
 * @returns {Promise} - A promise that resolves if the email is sent successfully, and rejects on failure.
 */
export const sendEmail = (templateParams) => {
  return emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID);
};
