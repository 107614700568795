import React from 'react';
import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import BookingButton from './BookingPopup/BookingButton'; // Import the BookingButton component

const BurgerMenu = ({ open, onClose }) => {
  // Function to handle click on menu items and close the menu
  const handleMenuItemClick = (path) => {
    onClose();
  };

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: { xs: 250, sm: 300 }, // Responsive width for the drawer
          bgcolor: 'background.paper',
          boxShadow: 3,
        },
      }}
    >
      <Box
        sx={{ width: '100%', position: 'relative' }}
        role="presentation"
      >
        {/* Close Button */}
        <IconButton
          sx={{ position: 'absolute', top: 8, right: 8 }}
          onClick={onClose}
          color="primary"
        >
          <CloseIcon sx={{ fontSize: 28 }} />
        </IconButton>

        {/* Navigation List */}
        <List sx={{ mt: 4 }}>
          <ListItem button component={Link} to="/" onClick={handleMenuItemClick}>
            <ListItemText primary="Αρχική" />
          </ListItem>
          <Divider />
          <ListItem button component={Link} to="/biography" onClick={handleMenuItemClick}>
            <ListItemText primary="Βιογραφικό" />
          </ListItem>
          <Divider />
          <ListItem button component={Link} to="/services" onClick={handleMenuItemClick}>
            <ListItemText primary="Υπηρεσίες" />
          </ListItem>
          <Divider />
          <ListItem button component={Link} to="/articles" onClick={handleMenuItemClick}>
            <ListItemText primary="Άρθρα" />
          </ListItem>
          <Divider />
          <ListItem button component={Link} to="/contact" onClick={handleMenuItemClick}>
            <ListItemText primary="Επικοινωνία" />
          </ListItem>
          <Divider />

          {/* Booking Button */}
          <Box sx={{ mt: 2, px: 2 }}>
            <BookingButton buttonText="Κλείστε Ραντεβού" fullWidth />
          </Box>
        </List>
      </Box>
    </Drawer>
  );
};

export default BurgerMenu;
